import Vue from 'vue';
import Vuex from 'vuex';
import Mutations from './mutations';
import User from './models/User';
import InitialState from './InitialState';
import Collection from './models/Collection';
import Form from './models/Form';
import Site from './models/Site';
import List from './models/List';
import Communication from './models/Communication';
import Theme from '../app/model/Theme';

Vue.use(Vuex);

export default new Vuex.Store({
  state: new InitialState(),
  getters: {
    isAdmin(state) {
      return state.user && state.user.role === 'ADMIN';
    },
    isMarketing(state) {
      return state.user && (state.user.role === 'ADMIN' || state.user.role === 'MARKETING');
    },
    getFormById: (state) => (formId: string) => state.forms.find((form) => form._id === formId),
    getCollectionById: (state) => (collectionId: string) =>
      state.collections.find((collection) => collection._id === collectionId),
  },
  mutations: {
    [Mutations.LOADING](state, loading: boolean) {
      state.loading = loading;
    },
    [Mutations.USER_SET](state, user: User) {
      state.user = user;
    },
    [Mutations.SITE_SELECT](state, site: Site) {
      state.selectedSite = site;
    },
    [Mutations.LISTS_SET](state, lists: List[]) {
      state.lists = lists;
    },
    [Mutations.LIST_SELECT](state, list: List) {
      state.selectedList = list;
    },
    [Mutations.LIST_ADD_OR_UPDATE](state, list: List) {
      if (list._id) {
        state.lists.splice(
          state.lists.findIndex((fList) => fList._id === list._id),
          1,
        );
      }
      state.lists.push(list);
    },
    [Mutations.COMMUNICATIONS_SET](state, communications: Communication[]) {
      state.communications = communications;
    },
    [Mutations.COMMUNICATION_SELECT](state, communication: Communication) {
      state.selectedCommunication = communication;
    },
    [Mutations.COMMUNICATIONS_SHOW_ONLY_DISPLAYED_SET](state, show: boolean | null) {
      state.communicationsShowOnlyDisplayed = show;
    },
    [Mutations.COLLECTIONS_SET](state, collections: Collection[]) {
      state.collections = collections;
    },
    [Mutations.FORMS_SET](state, forms: Form[]) {
      state.forms = forms;
    },
    [Mutations.THEME_SET](state, theme: Theme) {
      state.theme = theme;
    },
  },
  actions: {},
  modules: {},
  strict: true,
});
