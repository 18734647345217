
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

const props = Vue.extend({
  props: ['label', 'hint', 'value', 'disabled'],
});

@Component
export default class Toggle extends mixins(Vue, props) {}
