import Vue from 'vue';
import Axios from 'axios';
import Component from 'vue-class-component';
import User from '@shared/store/models/User';
import mutations from '@shared/store/mutations';
import LoginCredentials from '@shared/store/models/LoginCredentials';
import List from '@shared/store/models/List';
import Communication from '@shared/store/models/Communication';
import Http from '@shared/http/http';
import axios from '@shared/http/axios';
import axiosRepository from './axios.repository';
import Site from '../store/models/Site';
import { AppMessage } from '../app/model/AppMessage';

@Component
export default class HttpMixin extends Vue {
  protected http: Http;

  constructor() {
    super();
    const store = this.$store;
    this.http = {
      async getMyUser() {
        return axios.get('/users/me').then((response) => {
          //
          const user: User = response.data;
          // store.commit(mutations.USER_SET, user);
          return user;
        });
      },

      async loadCollections() {
        return axios.get('/collections').then((r) => r.data);
      },

      async loadSiteById(id: string) {
        return axios.get(`/sites/${id}`).then((r) => r.data);
      },

      async createSite(data: Site) {
        return axios.post('/sites', data).then((r) => r.data);
      },

      async deleteSiteById(id: string) {
        return axios.delete(`/sites/${id}`).then((r) => r.data);
      },

      async updateSiteById(id: string, data: Site) {
        return axios.patch(`/sites/${id}`, data).then((r) => r.data);
      },

      async loadForms() {
        return axios.get('/forms').then((r) => r.data);
      },

      async loadCommunications() {
        return axios.get('/communications').then((r) => r.data);
      },

      async loadCommunicationById(id: string) {
        return axios.get(`/communications/${id}`).then((r) => r.data);
      },

      async loadCommunicationsForSite(siteId: string) {
        return axios.get(`/communications/site/${siteId}`).then((r) => r.data);
      },

      async loadCommunicationsForList(listId: string) {
        return axios.get(`/communications/list/${listId}`).then((r) => r.data);
      },

      async saveCommunication(communication: Communication) {
        return axios.post('/communications', communication).then((r) => r.data);
      },

      async updateCommunication(communication: Communication) {
        return axios.put(`/communications/${communication._id}`, communication).then((r) => r.data);
      },

      async loadLists() {
        return axios.get('/lists').then((r) => r.data);
      },

      async saveList(list: List) {
        return axios.post('/lists', list).then((r) => r.data);
      },

      async updateList(list: List) {
        return axios.put(`/lists/${list._id}`, list).then((r) => r.data);
      },

      async login(credentials: LoginCredentials) {
        return axios
          .post('users/auth/login', { email: credentials.email, password: credentials.password })
          .then(() => this.getMyUser());
      },

      async logout() {
        return axios.get('/users/auth/logout').then(() => {
          store.commit(mutations.USER_SET, null);
        });
      },

      async loadAppMessages() {
        const currentEpoch = Date.now();
        const lastLoadEpoch = localStorage.lastAppMessagesLoading;
        if (currentEpoch - (lastLoadEpoch || 1) > 240000 || !localStorage.appMessages) {
          await Axios.get<string>(`${process.env.VUE_APP_APPMESSAGES_GSHEET}&r=${currentEpoch}`, {
            timeout: 2000,
          }).then((response) => {
            const messages: AppMessage[] = [];
            const csv = response.data;
            const lines = csv.split('\n');
            lines.shift();
            if (lines.length) {
              lines.forEach((line) => {
                const [type, message] = line.split('\t');
                messages.push({ type, message });
              });
            }
            localStorage.appMessages = JSON.stringify(messages);
            localStorage.lastAppMessagesLoading = currentEpoch;
            return messages;
          });
        }
        return localStorage.appMessages?.length ? JSON.parse(localStorage.appMessages) : [];
      },

      async uploadFileRD(formData: FormData, onUploadProgress: Function) {
        return axiosRepository.post('/upload', formData, {
          onUploadProgress: (e) => {
            const percentCompleted = Math.round((e.loaded * 100) / e.total);
            if (typeof onUploadProgress === 'function') onUploadProgress(percentCompleted);
          },
        });
      },

      async loadDataset(name: string) {
        return axiosRepository.get(`/all/${name}`).then((r) => r.data);
      },

      async setDatasetValue(name: string, key: string, value: string | any) {
        let stringValue: string;
        if (typeof value === 'string') stringValue = value;
        else stringValue = JSON.stringify(value);

        return axiosRepository.post(`/set/${name}/${key}`, stringValue, {
          headers: {
            'Content-Type': 'text/plain',
          },
        });
      },
    };
  }
}
