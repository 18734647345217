import Theme from '@/shared/app/model/Theme';
import defaultTheme from '@/shared/app/theme/default.theme';

const ecfTheme: Theme = {
  ...defaultTheme,
  customerName: 'ECF',
  primaryColor: '#46434F',
  secondaryColor: '#607d8b',
  accentColor: '#ffc107',
  errorColor: '#f44336',
  warningColor: '#ff9800',
  infoColor: '#3f51b5',
  successColor: '#4caf50',
};

export default ecfTheme;
