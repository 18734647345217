
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import HttpMixin from '@shared/http/http.mixin';
import mutations from '@shared/store/mutations';
import WithStateMixin from '@/shared/store/WithStateMixin';
import Snack from '@/shared/app/Snack.vue';
import { AppMessage } from '../model/AppMessage';

@Component({
  components: {
    Snack,
  },
})
export default class App extends mixins(Vue, HttpMixin, WithStateMixin) {
  private drawer = false;

  private appBoostrapped = false;

  private appMessages: AppMessage[] = [];

  beforeMount() {
    /*
     *  App bootstraping
     */
    this.http
      .getMyUser()
      .then(
        (user) => {
          if (this.$router.currentRoute.name === 'login') {
            this.$router.push({ name: 'home' });
          }
          this.$store.commit(mutations.USER_SET, user);
          return Promise.all([
            // Chargement des éléments
            this.http.loadLists().then((lists) => this.$store.commit(mutations.LISTS_SET, lists)),
            this.http
              .loadCollections()
              .then((collections) => this.$store.commit(mutations.COLLECTIONS_SET, collections)),
            this.http.loadForms().then((forms) => this.$store.commit(mutations.FORMS_SET, forms)),
          ]);
        },
        (reason) => {
          const { status } = reason.response;
          if (status === 401) {
            this.$store.commit(mutations.USER_SET, null);
            this.goToLogin();
          }
        },
      )
      .then(async () => {
        await this.loadAppMessages();
        setInterval(() => {
          this.loadAppMessages();
        }, 20000);
      })
      .finally(() => {
        this.$store.commit(mutations.LOADING, false);
        this.appBoostrapped = true;
      });
  }

  mounted() {
    this.drawer = this.$vuetify.breakpoint.lgAndUp;
  }

  get authenticated() {
    return Boolean(this.user);
  }

  get user() {
    return this.$store.state.user;
  }

  get version() {
    return process.env.VUE_APP_VERSION || '1';
  }

  get loading() {
    return this.$store.state.loading;
  }

  get items() {
    return [
      {
        icon: 'mdi-playlist-edit',
        text: 'Saisie',
        route: { name: 'home' },
      },
      {
        icon: 'mdi-cast',
        text: 'Communications',
        route: { name: 'communications' },
      },
      {
        icon: 'mdi-face-agent',
        text: "J'ai besoin d'aide / FAQ",
        route: { name: 'support' },
      },
    ];
  }

  logout() {
    this.http.logout().then(() => {
      this.goToLogin();
    });
  }

  goToLogin() {
    if (this.$router.currentRoute.name !== 'login') {
      this.$router.push({ name: 'login' });
    }
  }

  async loadAppMessages() {
    const msgs = await this.http.loadAppMessages();
    if (Array.isArray(msgs)) this.appMessages = msgs;
    else this.appMessages = [];
  }
}
