
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import SiteSearch from '@shared/components/SiteSearch.vue';
import Site from '@shared/store/models/Site';
import RolesMixin from '../store/mixins/roles.mixin';

@Component({
  components: { SiteSearch },
})
export default class Home extends mixins(Vue, RolesMixin) {
  siteSelect(selection: Site | null) {
    if (selection?._id) this.goToSite(selection._id);
  }

  goToSite(id: string) {
    this.$router.push({ name: 'site_home', params: { id } });
  }
}
