
import Vue from 'vue';
import Component from 'vue-class-component';
import { SnackOptions, SnackType } from './app.constants';

@Component
export default class Snack extends Vue {
  private value = false;
  private timeout = 5000;
  private text = '';
  private width: number | null = null;
  private color: string | null = null;

  mounted() {
    this.$root.$on('snack', this.snack);
  }

  snack(opts: SnackOptions) {
    this.reset();
    this.text = opts.text;
    if (opts.timeout) this.timeout = opts.timeout;
    if (opts.width) this.width = opts.width;

    if (opts.type !== undefined) {
      switch (opts.type) {
        case SnackType.ERROR:
          this.color = 'red darken-3';
          break;
        case SnackType.SUCCESS:
          this.color = 'green darken-3';
          break;
        case SnackType.WARNING:
          this.color = 'orange darken-3';
          break;
        default:
          this.color = null;
      }
    }
    this.value = true;
  }

  reset() {
    this.text = '';
    this.timeout = 5000;
    this.width = null;
    this.color = null;
  }
}
