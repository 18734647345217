
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import HttpMixin from '@shared/http/http.mixin';
import FormField from '@shared/store/models/FormField';
import Infos from './Infos.vue';
import Toggle from './Toggle.vue';
import MpSelect from './Select.vue';

const props = Vue.extend({
  props: ['field', 'form', 'value', 'effects', 'computeString'],
});

const FIELD_STATES = {
  INITIALIZING: 'initializing',
  OK: 'ok',
  SAVING: 'saving',
  ERROR: 'error',
};

@Component({
  components: {
    Infos,
    Toggle,
    MpSelect,
  },
})
export default class Field extends mixins(Vue, HttpMixin, props) {
  dialog = false;

  state = FIELD_STATES.OK;

  FIELD_STATES = FIELD_STATES;

  field!: FormField;

  updateValue(value: string) {
    if (this.field.key && this.form.datasets[this.field.alias]?.$datasetName) {
      const realDatasetName = this.form.datasets[this.field.alias].$datasetName;
      this.state = FIELD_STATES.SAVING;
      this.http.setDatasetValue(realDatasetName, this.field.key, value).then(
        () => {
          // this.form.datasets[this.field.alias][this.field.key] = value;
          Vue.set(this.form.datasets[this.field.alias], this.field.key, value);
          this.state = FIELD_STATES.OK;
        },
        () => {
          this.state = FIELD_STATES.ERROR;
        },
      );
    }
  }

  hasEffect(effect: string) {
    if (!this.effects) return false;
    return this.effects.indexOf(effect) !== -1;
  }

  get _collection() {
    return this.computeString(this.form, this.field.params.collection);
  }

  get rules() {
    const r = [];
    if (this.field.params.pattern) {
      r.push(
        (value: string) =>
          (value && value.match(this.field.params.pattern) !== null) ||
          'La valeur ne correspond pas au format attendu.',
      );
    }
    return r;
  }

  get hint() {
    if (!this.field.params.hint) return '';
    if (!this.computeString) return this.field.params.hint;
    return this.computeString(this.form, this.field.params.hint);
  }
}
