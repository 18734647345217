
import _ from 'lodash-es';
import Vue from 'vue';
import { AxiosError } from 'axios';
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import HttpMixin from '../http/http.mixin';
import Site, { emptySite } from '../store/models/Site';
import mutations from '../store/mutations';
import SnackMixin from '../app/mixins/snack.mixin';
import { SnackType } from '../app/app.constants';
import RolesMixin from '../store/mixins/roles.mixin';

@Component({})
export default class SiteEdition extends mixins(
  Vue,
  HttpMixin,
  SnackMixin,
  RolesMixin,
) {
  private site: Site | null = null;
  private edition = false;
  private valid = false;
  private json = '{}';
  private confirm = false;

  beforeMount() {
    if (!this.isAdmin) {
      this.accessDeniedSnack();
      this.$router.push('/');
    }
  }

  async mounted() {
    const siteId = this.$route.params.id;
    if (siteId) {
      this.edition = true;
      this.$store.commit(mutations.LOADING, true);
      this.site = await this.http.loadSiteById(siteId);
      this.$store.commit(mutations.LOADING, false);
    } else {
      this.site = emptySite();
    }
  }

  @Watch('site')
  siteWatch(newSite: Site) {
    if (newSite) {
      const clone = _.cloneDeep(newSite);
      delete clone._id;
      this.json = JSON.stringify(clone, null, 2);
    }
  }

  async validate() {
    console.log('validate called');
    this.$store.commit(mutations.LOADING, true);
    const data: Site = JSON.parse(this.json);
    let request: Promise<Site>;
    if (this.edition && this.site?._id) {
      request = this.http.updateSiteById(this.site._id, data).then(
        (returnedSite) => {
          this.snack({ text: 'Mise à jour réussie', type: SnackType.SUCCESS });
          return returnedSite;
        },
        (e: AxiosError) => {
          this.snack({
            text: `Échec de la mise à jour. Code ${e.response?.status}`,
            type: SnackType.ERROR,
          });
          throw e;
        },
      );
    } else {
      request = this.http.createSite(data).then(
        (returnedSite) => {
          this.snack({
            text: `Le site ${data.customerId} a bien été créé`,
            type: SnackType.SUCCESS,
          });
          return returnedSite;
        },
        (e: AxiosError) => {
          this.snack({
            text: `Échec de la mise à jour. Code ${e.response?.status}`,
            type: SnackType.ERROR,
          });
          throw e;
        },
      );
    }
    this.$store.commit(mutations.LOADING, false);
    request.then((returnedSite) => {
      this.$router.push(`/site/${returnedSite._id}`);
    });
  }

  get jsonValidationRules() {
    return [
      (v: string) => {
        try {
          JSON.parse(v);
        } catch (e) {
          return 'La syntaxe du json semble invalide';
        }
        return true;
      },
    ];
  }

  get loading() {
    return this.$store.state.loading;
  }
}
