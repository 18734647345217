import FormUsage from './FormUsage';

interface Site {
  _id?: string;
  name: string;
  customerId: string;
  filename: string;
  address: string;
  formUsages: FormUsage[];
}

export default Site;

export function emptySite(): Site {
  return {
    customerId: '',
    name: '',
    address: '',
    filename: '',
    formUsages: [],
  };
}
