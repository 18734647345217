
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import _ from 'lodash-es';
import SiteSearch from '@shared/components/SiteSearch.vue';
import CommunicationFilePreview from '@shared/components/CommunicationFilePreview.vue';
import CommunicationsFormHelpDialog from '@shared/components/CommunicationsFormHelpDialog.vue';
import IsoDatePicker from '@shared/components/DatePicker.vue';
import Communication from '@shared/store/models/Communication';
import User from '@shared/store/models/User';
import List from '@shared/store/models/List';
import Site from '@shared/store/models/Site';
import HttpMixin from '@shared/http/http.mixin';
import mutations from '@shared/store/mutations';

@Component({
  components: {
    IsoDatePicker,
    SiteSearch,
    ValidationProvider,
    ValidationObserver,
    CommunicationFilePreview,
    CommunicationsFormHelpDialog,
  },
})
export default class CommunicationsForm extends mixins(Vue, HttpMixin) {
  cloneFrom: Communication | null = null;

  helpDialog = false;

  communication: Communication = new Communication();

  zones = [
    { name: 'Écran 1', value: 'ecran_1' },
    { name: 'Écran 2', value: 'ecran_2' },
    { name: 'Écran 3', value: 'ecran_3' },
    { name: 'Écran 4', value: 'ecran_4' },
    { name: 'Écran 5', value: 'ecran_5' },
    { name: 'Écran 6', value: 'ecran_6' },
    { name: 'Écran 7', value: 'ecran_7' },
    { name: 'Écran 8', value: 'ecran_8' },
    { name: 'Écran 9', value: 'ecran_9' },
    { name: 'Écran 10', value: 'ecran_10' },
  ];

  beforeMount() {
    this.$store.commit(mutations.LOADING, true);
  }

  async mounted() {
    const route = this.$router.currentRoute;
    if (route.name === 'communications_edit' && route.params.id) {
      // Édition
      this.communication = await this.http.loadCommunicationById(
        route.params.id,
      );
      if (this.communication.global) {
        this.communication.global = false;
        this.globalChange();
      }
      // this.$store.commit(mutations.COMMUNICATION_SELECT, communication);
    } else if (route.name === 'communications_clone' && route.params.id) {
      this.cloneFrom = await this.http.loadCommunicationById(route.params.id);
      if (!this.cloneFrom) this.$router.push({ name: 'communications' });
      this.communication = _.cloneDeep(this.cloneFrom);
      delete this.communication._id;
      this.communication.name = `${this.communication.name} (copie)`;
      this.communication.marketing = this.isMarketing;
      this.communication.sites = [];
      this.communication.lists = [];
      this.communication.global = false;
      this.communication.mandatory = false;
      if (this.user && this.user.sites.length === 1) {
        this.communication.sites = [this.user.sites[0]];
      }
    } else {
      // Création
    }
    this.$store.commit(mutations.LOADING, false);
  }

  get edit() {
    return Boolean(this.communication?._id);
  }

  get lists(): List[] {
    return this.$store.state.lists;
  }

  get user(): User {
    return this.$store.state.user;
  }

  get listsActivated() {
    return this.communication.marketing && !this.communication.global;
  }

  get isMarketing() {
    return this.$store.getters.isMarketing;
  }

  get isAdmin() {
    return this.$store.getters.isAdmin;
  }

  get noMedia() {
    return (
      !this.communication.fileH &&
      !this.communication.fileC &&
      !this.communication.fileV
    );
  }

  get formattedSites() {
    if (this.communication && this.communication.sites) {
      if (
        !this.communication.marketing &&
        this.communication.sites.length > 0
      ) {
        return this.communication.sites[0];
      }
      return this.communication.sites;
    }
    return null;
  }

  marketingChange() {
    const { marketing, global } = this.communication;
    if (!marketing) {
      this.communication.global = false;
      this.communication.mandatory = false;
    }
    if (!global) {
      this.communication.sites = [];
      this.communication.lists = [];
    }
    this.validateTargets();
  }

  siteChange(selection: Site | Site[]) {
    if (selection) {
      if (!Array.isArray(selection)) {
        this.communication.sites = [selection._id!];
      } else this.communication.sites = selection.map((s) => s._id!);
    } else this.communication.sites = [];
  }

  globalChange() {
    const { global } = this.communication;
    if (global) {
      this.communication.sites = [];
      this.communication.lists = [];
    }
    this.validateTargets();
  }

  async validateTargets() {
    if (this.$refs.targetValidationObserver) {
      this.$nextTick(async () => {
        await (this.$refs.targetValidationObserver as any).validate();
      });
    }
  }

  async submit() {
    if (this.edit) {
      await this.http.updateCommunication(this.communication);
    } else {
      await this.http.saveCommunication(this.communication);
    }
    this.$router.push({ name: 'communications' });
  }

  @Watch('communication')
  wCommunication(c: Communication) {
    if (c && this.edit) document.title = `${c.name} - MyPlay`;
  }
}
