import Vue from 'vue';
import Component from 'vue-class-component';
import User from '../models/User';

@Component
export default class RolesMixin extends Vue {
  get isAdmin() {
    return this.$store.getters.isAdmin;
  }

  get isMarketing() {
    return this.$store.getters.isMarketing;
  }

  get user(): User | null {
    return this.$store.state.user;
  }
}
