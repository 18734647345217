
import Vue from 'vue';
import moment from 'moment';
import Component, { mixins } from 'vue-class-component';
import CommunicationsTableFilters from '@shared/components/CommunicationsTableFilters.vue';
import Communication from '@shared/store/models/Communication';
import mutations from '@shared/store/mutations';
import HttpMixin from '@shared/http/http.mixin';

const SORT_MODE = {
  DEFAULT: 0,
  NAME: 1,
  TYPE: 2,
};

const props = Vue.extend({
  props: ['communications', 'location'],
});

@Component({
  components: { CommunicationsTableFilters },
})
export default class CommunicationsTable extends mixins(Vue, HttpMixin, props) {
  searchInput = '';

  sortMode: number = SORT_MODE.DEFAULT;

  /* Header et tri du tableau */
  headers = [
    {
      text: 'Intitulé',
      align: 'start',
      value: 'name',
    },
    { text: 'Statut', value: 'endAt' },
    // { text: 'Durée (s)', value: 'duration' },
    { text: 'Type', value: 'marketing' },
    { text: 'Zone', value: 'zones' },
    { text: 'Activation', value: 'active', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  /*
      Status :
       0 = à venir
       1 = en cours
      -1 = terminée/passée
    */
  getStatus(communication: Communication) {
    const current = new Date();
    const startAt = new Date(communication.startAt);
    if (current < startAt) return 0;
    const endAt = new Date(communication.endAt);
    if (current < endAt) return 1;
    return -1;
  }

  daysBefore(date: string) {
    return moment(date).diff(new Date(), 'days');
  }

  getFilesize(imageSize: number) {
    return Math.round((imageSize / 1000000) * 100) / 100;
  }

  isEditableForMe(communication: Communication) {
    if (this.isMarketing) return true;
    if (communication.marketing) return false;
    return true;
  }

  isEnabled(communication: Communication) {
    if (communication.mandatory) return true;
    if (this.location && communication.disabled) {
      return communication.disabled.indexOf(this.location._id) === -1;
    }
    return false;
  }

  isPattern(communication: Communication) {
    /* eslint-disable operator-linebreak */
    return (
      communication.marketing &&
      !communication.global &&
      !communication.lists.length &&
      !communication.sites.length
    );
  }

  switchActivation(communication: Communication) {
    const c = communication;
    if (this.location) {
      const index = c.disabled.indexOf(this.location._id);
      const oldValue = c.disabled;
      if (index === -1) {
        c.disabled.push(this.location._id);
      } else {
        c.disabled.splice(index, 1);
      }
      this.http.updateCommunication(c).catch(() => {
        c.disabled = oldValue;
      });
    }
  }

  showOnlyDisplayedChange(value: boolean | null) {
    this.$store.commit(mutations.COMMUNICATIONS_SHOW_ONLY_DISPLAYED_SET, value);
  }

  get showOnlyEnabled() {
    if (this.communications && Array.isArray(this.communications)) {
      let toUse = [...this.communications];
      toUse = toUse.filter((comm) => this.isEnabled(comm));
      return toUse.length;
    }
    return null;
  }

  get showOnlyDisabled() {
    if (this.communications && Array.isArray(this.communications)) {
      let toUse = [...this.communications];
      toUse = toUse.filter((comm) => !this.isEnabled(comm));
      return toUse.length;
    }
    return null;
  }

  get isMarketing() {
    return this.$store.getters.isMarketing;
  }

  get isAdmin() {
    return this.$store.getters.isAdmin;
  }

  /**
   * Renvoie les communications triées (et filtrées si recherche par nom active)
   */
  get orderedCommunications(): Communication[] {
    if (this.communications && Array.isArray(this.communications)) {
      // Recherche par nom ?
      let toUse = [...this.communications]; // copie array (vuex strict mode)
      if (this.searchInput) {
        const words = this.searchInput.toLowerCase().split(' ');
        toUse = this.communications.filter((comm) =>
          words.some((w) => w && comm.name.toLowerCase().indexOf(w) !== -1),
        );
      }

      // Filtres communications actives/inactives
      if (this.location) {
        if (this.$store.state.communicationsShowOnlyDisplayed) {
          toUse = toUse.filter((c) => this.isEnabled(c));
        } else if (
          this.$store.state.communicationsShowOnlyDisplayed !== null &&
          !this.$store.state.communicationsShowOnlyDisplayed
        ) {
          toUse = toUse.filter((c) => !this.isEnabled(c));
        }
      }

      let sortFn;
      switch (this.sortMode) {
        case SORT_MODE.TYPE:
          sortFn = (a: Communication, b: Communication) => {
            if (this.isPattern(a) && !this.isPattern(b)) return 1;
            if (!this.isPattern(a) && this.isPattern(b)) return -1;
            if (!a.marketing && b.marketing) return -1;
            if (a.marketing && !b.marketing) return 1;
            return 0;
          };
          break;
        case SORT_MODE.NAME:
          sortFn = (a: Communication, b: Communication) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          };
          break;
        // Tri par défaut : par status & date
        default:
          sortFn = (a: Communication, b: Communication) => {
            const aStatus = this.getStatus(a);
            const bStatus = this.getStatus(b);
            // par statut
            if (aStatus !== bStatus) {
              if (aStatus > bStatus) {
                return -1;
              }
              if (bStatus < aStatus) {
                return 1;
              }
            }

            // Reached: le statut est le même (aStatus===bStatus)
            const aEndAt = moment(a.endAt);
            const bEndAt = moment(b.endAt);
            const aStartAt = moment(a.startAt);
            const bStartAt = moment(b.startAt);

            // en cours : celle qui se finit le plus tôt
            if (aStatus === 1) {
              if (aEndAt.isBefore(bEndAt)) return -1;
              if (bEndAt.isBefore(aEndAt)) return 1;
            }
            // terminées : celle qui s'est finie le plus récemment
            if (aStatus === -1) {
              if (aEndAt.isBefore(bEndAt)) return 1;
              if (bEndAt.isBefore(aEndAt)) return -1;
            }
            // à venir : celle qui commence le plus tôt, qui fini le plus tôt
            if (aStatus === 0) {
              if (aStartAt.isBefore(bStartAt)) return -1;
              if (bStartAt.isBefore(aStartAt)) return 1;
              if (aEndAt.isBefore(bEndAt)) return -1;
              if (bEndAt.isBefore(aEndAt)) return 1;
            }

            // fallback : par ordre alphabétique
            if (a.name < b.name) return -1;
            return 0;
          };
      }

      return toUse.sort(sortFn);
    }
    return [];
  }
}
