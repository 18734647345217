
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

const props = Vue.extend({
  props: ['orientation', 'url', 'limitHeight', 'zoom'],
});

@Component
export default class Preview extends mixins(Vue, props) {
  get aspectRatio() {
    switch (this.orientation) {
      case 'vertical':
        return 9 / 16;
      case 'horizontal':
        return 16 / 9;
      case 'stretch45':
        return 32 / 9;
      default:
        return 16 / 9;
    }
  }

  get repositoryUrl() {
    return `${process.env.VUE_APP_REPOSITORY_URL}/${this.url}`;
  }

  reload() {
    if (this.$refs.iframe) {
      const iframe = this.$refs.iframe as HTMLIFrameElement;
      // eslint-disable-next-line no-self-assign
      iframe.src = iframe.src;
    }
  }
}
