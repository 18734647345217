
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Info from '@shared/store/models/Info';

const props = Vue.extend({
  props: ['label', 'hint', 'collection', 'value', 'disabled'],
});

@Component
export default class Infos extends mixins(Vue, props) {
  items: Info[] = [];

  index = -1;

  dialog = false;

  get arrayValue(): Info[] {
    if (this.value) {
      try {
        return JSON.parse(this.value);
      } catch (e) {
        return [];
      }
    }
    return [];
  }

  get selected() {
    if (this.index !== undefined) {
      return this.items[this.index];
    }
    return undefined;
  }

  get _collection() {
    if (this.collection) return this.$store.getters.getCollectionById(this.collection);
    return null;
  }

  makeRDUrl(url: string) {
    return `${process.env.VUE_APP_REPOSITORY_URL}/${url}`;
  }

  add() {
    this.items.push({
      name: 'Nouvel élément',
      active: true,
      duration: 8,
      url: null,
      text: '',
    });
    this.index = this.items.length - 1;
  }

  remove(item: Info) {
    const index = this.items.indexOf(item);
    this.items.splice(index, 1);
  }

  apply() {
    this.dialog = false;
    this.$emit('input', JSON.stringify(this.items));
  }

  @Watch('arrayValue')
  wArrayValue(newValues: Info[]) {
    if (!this.dialog) this.items = newValues;
  }

  open() {
    this.items = this.arrayValue;
    if (this.items.length) {
      this.index = 0;
    }
    this.dialog = true;
  }
}
