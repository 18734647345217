
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import HttpMixin from '@shared/http/http.mixin';
import RolesMixin from '@shared/store/mixins/roles.mixin';
import Site from '@shared/store/models/Site';
import mutations from '@shared/store/mutations';
import SnackMixin from '../app/mixins/snack.mixin';
import { SnackType } from '../app/app.constants';

@Component
export default class SiteHome extends mixins(Vue, HttpMixin, RolesMixin, SnackMixin) {
  confirm = false;

  async beforeMount() {
    this.$store.commit(mutations.LOADING, true);
    const route = this.$router.currentRoute;
    if (route.name === 'site_home' && route.params.id) {
      const site = await this.http.loadSiteById(route.params.id);
      this.$store.commit(mutations.SITE_SELECT, site);
    }
    this.$store.commit(mutations.LOADING, false);
  }

  get site(): Site | undefined {
    return this.$store.state.selectedSite;
  }

  @Watch('site')
  wSite(site: Site) {
    if (site) document.title = `${site.customerId} ${site.name} - MyPlay`;
  }

  get showLocationSyncExtension() {
    return typeof this.$options.components?.LocationSyncComponent !== 'undefined';
  }

  async deleteSite() {
    if (!this.site?._id) return;
    this.$store.commit(mutations.LOADING, true);
    await this.http.deleteSiteById(this.site._id).then(
      () => {
        this.snack({
          text: `Le site ${this.site?.customerId} a bien été supprimé`,
          type: SnackType.SUCCESS,
        });
        this.$router.push('/');
      },
      () => {
        this.snack({ type: SnackType.ERROR, text: 'Une erreur est survenue' });
      },
    );
    this.$store.commit(mutations.LOADING, false);
  }
}
